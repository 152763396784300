import React from "react";
import clsx from "clsx";
import { Text, Container, Image, Button } from "@atoms";
import { m } from "framer-motion";

const ImageCopy = ({
  copy,
  button,
  secondaryLink,
  image,
  color,
  reverse,
  shape,
  className: _className,
  textSize,
}) => {
  const transition = { duration: 2.5, ease: [0.22, 1, 0.36, 1] };
  // const variants = {
  //   shapes: {
  //     visible: {
  //       opacity: 1,
  //       x: 0,
  //       y: 0,
  //       transition,
  //     },
  //     hidden: {
  //       opacity: 0,
  //       x: reverse ? 2.5 : -2.5,
  //       y: 2.5,
  //       transition,
  //     },
  //   },
  //   rounded: {
  //     visible: {
  //       opacity: 1,
  //       x: 0,
  //       y: 0,
  //       transition,
  //     },
  //     hidden: {
  //       opacity: 0,
  //       x: reverse ? 2.5 : -2.5,
  //       y: -2.5,
  //       transition,
  //     },
  //   },
  //   square: {
  //     visible: {
  //       opacity: 1,
  //       transition,
  //     },
  //     hidden: {
  //       opacity: 0,
  //       transition,
  //     },
  //   },
  // };

  return (
    <section
      className={clsx("my-10 sm:mb-20 sm:mt-20 md:mt-32", _className)}
    >
      <Container>
        <div
          className={clsx("md:gap-18 grid md:grid-cols-2 lg:gap-24", {
            "gap-4": shape === "square",
            "gap-12": shape !== "square",
          })}
        >
          {/* text container */}
          <div
            className={clsx(
              "relative z-20 flex flex-col items-start justify-center",
              {
                "order-2": reverse,
                "order-2 md:order-1": !reverse,
              }
            )}
          >
            <Text
              variant={textSize === "base" ? "body" : "sm"}
              className="prose-h3:text-slate"
              richText
            >
              {copy}
            </Text>
            {(button?.url || secondaryLink?.url) && (
              <div className="flex flex-wrap items-center gap-3 mt-8">
                {button?.url && (
                  <div>
                    <Button to={button.url} color="purple" size="sm">
                      {button.text}
                    </Button>
                  </div>
                )}
                {secondaryLink?.url && (
                  <div>
                    <Button
                      color="transparent"
                      outline
                      to={secondaryLink?.url}
                      size="sm"
                    >
                      {secondaryLink.text}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* image container */}
          <div
            className={clsx(
              "relative flex h-full min-h-[40vh] items-end md:max-h-[20rem] md:items-stretch lg:max-h-[26rem] xl:max-h-[32rem]",
              {
                "order-1 justify-end": reverse,
                "order-1 justify-start md:order-2": !reverse,
              }
            )}
          >
            <div
              className={clsx(
                "relative z-10 w-full overflow-hidden shadow-xl",
                {
                  "h-48 sm:h-64 md:h-auto": shape !== "shapes",
                  "rounded-3xl": shape === "rounded",
                  "h-full rounded-t-3xl": shape === "shapes",
                  "rounded-bl-3xl rounded-br-6xl":
                    reverse && shape === "shapes",
                  "rounded-bl-6xl rounded-br-3xl":
                    !reverse && shape === "shapes",
                  "bg-pink": color === "pink",
                  "bg-teal": color === "teal",
                  "bg-yellow": color === "yellow",
                }
              )}
            >
              <Image
                image={image}
                fill
                className={clsx({
                  "rounded-br-6xl": shape === "square",
                  "rounded-3xl": shape === "rounded",
                })}
              />
            </div>
            {/* background shape */}
            {shape === "shapes" && (
              <m.div
                viewport={{
                  once: true,
                  amount: "all",
                  margin: "10px",
                }}
                initial={{ opacity: 0, x: reverse ? -2.5 : 2.5, y: -2.5 }}
                whileInView={{ opacity: 1, x: 0, y: 0 }}
                transition={transition}
                className={clsx("absolute inset-0 flex", {
                  "justify-start": reverse,
                  "justify-end": !reverse,
                })}
              >
                <div
                  className={clsx(
                    "relative h-full max-h-[20rem] w-full rounded-b-3xl md:-top-12 lg:max-h-[32rem]",
                    {
                      "-translate-x-6 -translate-y-6 rounded-tl-6xl rounded-tr-3xl md:-left-12 md:translate-x-0 md:translate-y-0 md:rounded-br-6xl":
                        reverse,
                      "-translate-y-6 translate-x-6 rounded-tl-3xl rounded-tr-6xl md:-right-12 md:translate-x-0 md:translate-y-0 md:rounded-bl-6xl":
                        !reverse,
                      "bg-purple": color === "purple",
                      "bg-pink": color === "pink",
                      "bg-teal": color === "teal",
                      "bg-yellow": color === "yellow",
                    }
                  )}
                />
              </m.div>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

ImageCopy.defaultProps = {
  color: "teal",
  reverse: false,
  shape: "shapes",
};

export default ImageCopy;
